import { IDropdownOption, MessageBarType, Stack } from '@fluentui/react';
import { Accordion, Dropdown, KeyValuePairSubmitOutcome, TextField, useToast } from '@h2oai/ui-kit';
import { FormEvent, useCallback, useEffect, useRef, useState } from 'react';

import { defaultVersion } from '../../../../aiem/defaults';
import { useEngine } from '../../../../aiem/engine/hooks';
import {
  AIEMOpType,
  AIEngine,
  EngineConstraintSet,
  EngineProfileOption,
  EngineVersion,
  ValidEngineType,
} from '../../../../aiem/engine/types';
import { AdjustedDAIProfile } from '../../../../aiem/gen/ai/h2o/engine/v1/adjusted_dai_profile_pb';
import { Engine_Type } from '../../../../aiem/gen/ai/h2o/engine/v1/engine_pb';
import { toBigIntString } from '../../../../aiem/gen/runtime';
import { ConstraintType } from '../../../../aiem/types';
import { extractWorkspace } from '../../../../aiem/utils';
import { useFormAttributes } from '../../../../utils/utils';
import { EditablePanelFooter } from '../../../EditablePanelFooter/EditablePanelFooter';
import {
  DriverlessEngineProfileOptionKeyType,
  DriverlessEngineSizeOptions,
  H2OEngineProfileOptionKeyType,
  H2OEngineSizeOptions,
} from '../../constants';
import { isValidEngine, isValidExistingEngine } from '../../utils';
import { AdvancedConfiguration } from '../AdvancedConfiguration/AdvancedConfiguration';
import { ConstraintInput } from '../ConstraintInput/ConstraintInput';
import { DisplayAndId } from '../DisplayAndId/DisplayAndId';
import ConfigureCompressedDataEngine from './components/ConfigureCompressedDataEngine';
import ConfigureCustomEngineProfile from './components/ConfigureCustomEngineProfile';
import ConfigureRawDataEngine from './components/ConfigureRawDataEngine';
import DisplayPresetEngine from './components/DisplayPresetEngineProfile';

export type EngineConfigurationProps = {
  op: AIEMOpType;
  engine: AIEngine;
  onSave: () => any;
  onCancel: () => any;
};

export function EngineConfiguration({ op, engine, onCancel, onSave }: EngineConfigurationProps) {
  const { listDAIEngineSizeOptions, opOnEngine } = useEngine(),
    { inputContainerProps, inputRowProps, sliderContainerProps, sliderFormRowProps } = useFormAttributes(),
    { listVersions, getConstraintSet } = useEngine();
  const updateEngine = (eng: Partial<AIEngine>) => {
    const newEngine = { ...engineState, ...eng };
    setEngineState(newEngine);
    setIsEngineValid(validateEngine(newEngine));
  };
  const validateEngine = (e: AIEngine): boolean => {
    if (op === AIEMOpType.create) {
      return isValidEngine(e);
    } else {
      return isValidExistingEngine(e);
    }
  };
  const { addToast } = useToast();
  const createSuccessToast = useCallback(
    (displayName: string, op: AIEMOpType) => {
      addToast({
        messageBarType: MessageBarType.success,
        message:
          op === AIEMOpType.create
            ? `Engine "${displayName}" has been successfully created and it is now starting.`
            : `Engine "${displayName}" has been successfully updated.`,
      });
    },
    [addToast]
  );
  const engineType = engine?.engineType,
    engineSizeOptionKeys =
      engine?.engineType === Engine_Type.DRIVERLESS_AI
        ? DriverlessEngineProfileOptionKeyType
        : H2OEngineProfileOptionKeyType,
    engineSizeOptions = useRef<EngineProfileOption[]>(
      engine?.engineType === Engine_Type.DRIVERLESS_AI ? DriverlessEngineSizeOptions : H2OEngineSizeOptions
    ),
    [engineState, setEngineState] = useState<AIEngine>({ ...engine }),
    [isEngineValid, setIsEngineValid] = useState<boolean>(validateEngine(engine)),
    [profile, setProfile] = useState<AdjustedDAIProfile | undefined>(undefined),
    [selectedVersion, setSelectedVersion] = useState<string>(defaultVersion),
    [versions, setVersions] = useState<IDropdownOption[]>([]),
    [selectedProfile, setSelectedProfile] = useState<
      DriverlessEngineProfileOptionKeyType | H2OEngineProfileOptionKeyType
    >(),
    [constraintSet, setConstraintSet] = useState<EngineConstraintSet>(),
    handleVersionChange = (_e: FormEvent<HTMLDivElement>, { key: value }: any) => {
      setSelectedVersion(value);
      updateEngine({ version: value });
    },
    onDisplayNameChange = (value: string) => updateEngine({ displayName: value }),
    onIdChange = (value: string) => updateEngine({ id: value }),
    onRenderSizeOption = (option: any) => {
      return (
        <span style={{ width: '100%' }} data-test={option?.dataTest}>
          {option?.text}
        </span>
      );
    },
    getDefaultEngineFromConstraintSet = (constraints: EngineConstraintSet): Partial<AIEngine> => {
      return {
        cpu: Number(constraints?.cpu?.default),
        gpu: Number(constraints?.gpu?.default),
        memoryBytes: constraints?.memoryBytes?.default,
        storageBytes: constraints?.storageBytes?.default,
        nodeCount: Number(constraints?.nodeCount?.default),
        maxIdleDuration: String(constraints?.maxIdleDuration?.default),
        maxRunningDuration: String(constraints?.maxRunningDuration?.default),
      };
    },
    getEngineFromSelectedSizeOption = (selectedSizeOption?: EngineProfileOption) => {
      return {
        cpu: selectedSizeOption?.cpu ?? 0,
        gpu: selectedSizeOption?.gpu ?? 0,
        memoryBytes: selectedSizeOption?.memoryBytes || toBigIntString('0'),
        storageBytes: selectedSizeOption?.storageBytes || toBigIntString('0'),
      };
    },
    selectSize = (
      size: DriverlessEngineProfileOptionKeyType | H2OEngineProfileOptionKeyType,
      engineSizes?: EngineProfileOption[]
    ): EngineProfileOption | undefined => {
      let selectedSizeOption: EngineProfileOption | undefined;
      engineSizes = engineSizes || engineSizeOptions.current;
      setSelectedProfile(size);
      if (size !== engineSizeOptionKeys.custom && engineType === Engine_Type.DRIVERLESS_AI) {
        selectedSizeOption = engineSizes.find((option) => option.key === size);
        // note that DAI Engines cannot change their storage once created.
        // TODO: When DAI Engines are allowed to change their storage after creation, this logic must be removed:
        if (selectedSizeOption && op === AIEMOpType.edit) {
          selectedSizeOption = { ...selectedSizeOption, storageBytes: engine.storageBytes };
        }
        setProfile(selectedSizeOption);
        updateEngine(getEngineFromSelectedSizeOption(selectedSizeOption));
      } else if (
        size === engineSizeOptionKeys.custom &&
        engineType === Engine_Type.DRIVERLESS_AI &&
        op !== AIEMOpType.create
      ) {
        setProfile(undefined);
        updateEngine(engine);
      } else if (constraintSet) {
        updateEngine(getDefaultEngineFromConstraintSet(constraintSet));
      }
      return selectedSizeOption;
    };
  const loadVersions = useCallback(async (): Promise<string | undefined> => {
    let defaultVersion;
    const versions = await listVersions(engineType as ValidEngineType),
      options =
        versions?.map(({ key, version: text, isDefault }: EngineVersion) => {
          if (isDefault && key) {
            setSelectedVersion(key);
            defaultVersion = key;
          }
          return { key, text } as IDropdownOption;
        }) || [];
    setVersions(options);
    return defaultVersion;
  }, [listVersions, setSelectedVersion, setVersions]);
  const EngineProfileComponent = (function () {
    if (
      selectedProfile === DriverlessEngineProfileOptionKeyType.custom ||
      selectedProfile === H2OEngineProfileOptionKeyType.custom
    ) {
      return ConfigureCustomEngineProfile;
    }
    if (selectedProfile === H2OEngineProfileOptionKeyType.compressed) return ConfigureCompressedDataEngine;
    if (selectedProfile === H2OEngineProfileOptionKeyType.raw) return ConfigureRawDataEngine;
    return DisplayPresetEngine;
  })();
  useEffect(() => {
    const loadData = async () => {
      let defaultEngine: Partial<AIEngine> = {};
      const loadedSet = await getConstraintSet(
        op === AIEMOpType.create ? undefined : extractWorkspace(engineState?.name),
        engineType
      );
      if (loadedSet) {
        setConstraintSet(loadedSet);
        defaultEngine = getDefaultEngineFromConstraintSet(loadedSet);
      }
      if (engineType === Engine_Type.DRIVERLESS_AI) {
        const profiles = (await listDAIEngineSizeOptions()) as EngineProfileOption[];
        engineSizeOptions.current = profiles;
        const size =
          op === AIEMOpType.create
            ? profiles[0].key || DriverlessEngineProfileOptionKeyType.custom
            : DriverlessEngineProfileOptionKeyType.custom;

        const selectedSizeOption = selectSize(size, profiles);
        defaultEngine = {
          ...defaultEngine,
          ...getEngineFromSelectedSizeOption(selectedSizeOption),
        };
      } else {
        setSelectedProfile(H2OEngineProfileOptionKeyType.custom);
      }
      if (op === AIEMOpType.create) {
        const defaultVersion = (await loadVersions()) || '';
        updateEngine({ ...defaultEngine, version: defaultVersion });
      }
    };
    loadData();
  }, []);
  const isNew = useCallback((): boolean => {
    if (op === AIEMOpType.create) {
      return true;
    }
    return JSON.stringify(engine) !== JSON.stringify(engineState);
  }, [engine, engineState]);
  const saveEngine = async () => {
    if (op === AIEMOpType.create) {
      const res = await opOnEngine(engineState, AIEMOpType.create);
      if (res) {
        onSave();
        createSuccessToast(String(engineState!.displayName), AIEMOpType.create);
      } else {
        onCancel();
      }
    } else {
      const res = await opOnEngine(engineState, AIEMOpType.update);
      if (res) {
        onSave();
        createSuccessToast(String(engineState!.displayName), AIEMOpType.update);
      } else {
        onCancel();
      }
    }
  };
  const advancedConfigurationRef = useRef<any>();
  const waitingForEngineStateUpdate = useRef<boolean>(false);
  const onClickSave = useCallback(() => {
    if (advancedConfigurationRef.current) {
      waitingForEngineStateUpdate.current = true;
      const saveOutcome = advancedConfigurationRef.current.submitKeyValue();
      switch (saveOutcome) {
        case KeyValuePairSubmitOutcome.UNSUCCESSFUL:
          waitingForEngineStateUpdate.current = false;
          saveEngine();
          break;
        case KeyValuePairSubmitOutcome.SUCCESSFUL:
          waitingForEngineStateUpdate.current = true;
          break;
        case KeyValuePairSubmitOutcome.INVALID:
          waitingForEngineStateUpdate.current = false;
          break;
        default:
          saveEngine();
      }
    } else {
      saveEngine();
    }
  }, [saveEngine, advancedConfigurationRef.current, waitingForEngineStateUpdate.current]);
  // if the engineState has been updated, and we have been waiting for an update,
  useEffect(() => {
    if (waitingForEngineStateUpdate.current) {
      waitingForEngineStateUpdate.current = false;
      saveEngine();
    }
  }, [engineState]);
  return (
    <div style={{ paddingBottom: '50px' }}>
      <form>
        <Stack>
          <DisplayAndId
            engine={engine}
            onDisplayNameChange={onDisplayNameChange}
            onIdChange={onIdChange}
            editableId={op === AIEMOpType.create}
          />
          <Stack {...inputRowProps}>
            <Stack {...inputContainerProps}>
              {op === AIEMOpType.create ? (
                <Dropdown
                  label="Version"
                  placeholder="Select a Version"
                  options={versions}
                  selectedKey={selectedVersion}
                  onChange={handleVersionChange}
                />
              ) : (
                <TextField
                  name="version"
                  label="Version"
                  value={engineState?.version}
                  onChange={(e: any) => updateEngine({ version: e.event.value })}
                  readOnly
                />
              )}
            </Stack>
          </Stack>
          <Stack {...inputRowProps}>
            <Stack {...inputContainerProps}>
              <Dropdown
                label="Profile"
                selectedKey={selectedProfile}
                options={engineSizeOptions.current}
                loadingMessage={constraintSet ? undefined : 'Loading...'}
                onRenderOption={onRenderSizeOption}
                onChange={async (_e: FormEvent<HTMLDivElement>, option: any) => {
                  const { key: value } = option;
                  selectSize(value);
                }}
              />
            </Stack>
          </Stack>
          <EngineProfileComponent
            engine={engineState}
            constraintSet={constraintSet}
            profile={profile}
            modifyEngine={updateEngine}
            operationCreate={op === AIEMOpType.create}
          />
          <Accordion title="Timeout Configuration" isClose styles={{ title: { fontWeight: 400 } }}>
            <Stack {...sliderFormRowProps} styles={{ root: { width: 400, height: 77 } }}>
              <Stack {...sliderContainerProps}>
                <ConstraintInput
                  constraintType={ConstraintType.MAXIDLEDURATION}
                  constraint={constraintSet?.[ConstraintType.MAXIDLEDURATION]}
                  value={engineState?.[ConstraintType.MAXIDLEDURATION]}
                  onChange={(_event, value) => {
                    const fieldName = ConstraintType.MAXIDLEDURATION as string;
                    updateEngine({ [fieldName]: value });
                  }}
                  label="Max Idle Time (Hours)"
                  tooltip="Specify the maximum idle time of the Driverless AI instance. Instance will pause if it is idle for longer
                    than max idle time. When the instance pauses, it can be started again."
                />
              </Stack>
            </Stack>
            <Stack {...sliderFormRowProps} styles={{ root: { width: 400, height: 77 } }}>
              <Stack {...sliderContainerProps}>
                <ConstraintInput
                  label="Max Up Time (Hours)"
                  constraintType={ConstraintType.MAXRUNNINGDURATION}
                  constraint={constraintSet?.[ConstraintType.MAXRUNNINGDURATION]}
                  value={engineState?.[ConstraintType.MAXRUNNINGDURATION]}
                  onChange={(_event, value) => {
                    const fieldName = ConstraintType.MAXRUNNINGDURATION as string;
                    updateEngine({ [fieldName]: value });
                  }}
                  tooltip="Set the duration after which the instance automatically pauses. When the instance pauses, it can be started again."
                />
              </Stack>
            </Stack>
          </Accordion>

          {engineType === Engine_Type.DRIVERLESS_AI && (
            <Accordion
              title="Advanced Configuration (config.toml)"
              isClose
              styles={{
                title: { fontWeight: 400 },
                root: { marginTop: 12, marginBottom: 40, height: 'unset !important' },
              }}
            >
              <AdvancedConfiguration
                ref={advancedConfigurationRef}
                config={engineState.config || {}}
                onConfigChange={(config) => {
                  updateEngine({ config });
                }}
              />
            </Accordion>
          )}
        </Stack>
      </form>
      <EditablePanelFooter
        onCancel={onCancel}
        onSave={onClickSave}
        closeButtonText={op === AIEMOpType.create ? 'Back' : 'Close'}
        saveButtonText={op === AIEMOpType.create ? `Create` : `Save`}
        saveButtonDisabled={!constraintSet || !isEngineValid || !isNew()}
      />
    </div>
  );
}
