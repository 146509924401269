import {
  BasicList,
  IBasicListProps,
  Item,
  itemStylesTag,
  loaderStylesSpinnerDefault,
  loaderStylesSpinnerTag,
} from '@h2oai/ui-kit';

import { BadgeData, useEngine } from '../../../../aiem/engine/hooks';
import { EngineState } from '../../../../aiem/engine/types';
import { isTransitionalState } from '../../../../aiem/engine/utils';
import { Engine_State, Engine_Type } from '../../../../aiem/gen/ai/h2o/engine/v1/engine_pb';

type BadgesProps = {
  type?: Engine_Type;
  state?: EngineState;
};

export function Badges({ type, state }: BadgesProps) {
  const { EngineStateMap, getEngineTypeData } = useEngine(),
    typeStateData = { type: getEngineTypeData(type), state: state ? EngineStateMap.get(state) : undefined },
    data = Object.values(typeStateData).filter((v) => !!v) as BadgeData[];

  const listProps: IBasicListProps<BadgeData> = {
    idField: 'id',
    labelField: 'title',
    horizontal: true,
    data,
    itemRenderer: (d: BadgeData) => (
      <Item
        styles={itemStylesTag}
        idField="id"
        labelField="title"
        styleField="style"
        data={{
          id: d.id,
          title: d.title,
          style: {
            backgroundColor: d.backgroundColor,
            color: d.color,
            borderLeft: d.borderLeft,
          },
        }}
        loading={!!(d?.id && isTransitionalState(d.id as Engine_State))}
        loaderProps={{
          label: d.title,
          styles: [loaderStylesSpinnerDefault, loaderStylesSpinnerTag],
        }}
      />
    ),
  };
  return <BasicList {...listProps}></BasicList>;
}
