import {
  DriverlessEngineProfileOptionKeyType,
  H2OEngineProfileOptionKeyType,
} from '../../components/AIEnginesPage/constants';
import { AdjustedDAIProfile } from '../gen/ai/h2o/engine/v1/adjusted_dai_profile_pb';
import { DAIEngineConstraintSet } from '../gen/ai/h2o/engine/v1/dai_engine_constraint_set_pb';
import {
  GetDAIEngineConstraintSetRequest,
  GetDAIEngineConstraintSetResponse,
} from '../gen/ai/h2o/engine/v1/dai_engine_constraint_set_service_pb';
import { DAIEngine, DAIEngine_State } from '../gen/ai/h2o/engine/v1/dai_engine_pb';
import {
  CreateDAIEngineRequest,
  DAIEngineServiceMigrateCreatorRequest,
  DAIEngineServiceUpgradeVersionRequest,
  DAIEngineServiceUpgradeVersionResponse,
  DeleteDAIEngineRequest,
  GetDAIEngineRequest,
  ListDAIEnginesRequest,
  PauseDAIEngineRequest,
  ResumeDAIEngineRequest,
  UpdateDAIEngineRequest,
} from '../gen/ai/h2o/engine/v1/dai_engine_service_pb';
import { DAIVersion } from '../gen/ai/h2o/engine/v1/dai_version_pb';
import { ListDAIVersionsRequest } from '../gen/ai/h2o/engine/v1/dai_version_service_pb';
import { Engine, Engine_State, Engine_Type } from '../gen/ai/h2o/engine/v1/engine_pb';
import { ListEnginesRequest } from '../gen/ai/h2o/engine/v1/engine_service_pb';
import { H2OEngineConstraintSet } from '../gen/ai/h2o/engine/v1/h2o_engine_constraint_set_pb';
import {
  GetH2OEngineConstraintSetRequest,
  GetH2OEngineConstraintSetResponse,
} from '../gen/ai/h2o/engine/v1/h2o_engine_constraint_set_service_pb';
import { H2OEngine, H2OEngine_State } from '../gen/ai/h2o/engine/v1/h2o_engine_pb';
import {
  CalculateH2OEngineSizeCompressedDatasetRequest,
  CalculateH2OEngineSizeRawDatasetRequest,
  CreateH2OEngineRequest,
  DeleteH2OEngineRequest,
  GetH2OEngineRequest,
  ListH2OEnginesRequest,
} from '../gen/ai/h2o/engine/v1/h2o_engine_service_pb';
import { H2OVersion } from '../gen/ai/h2o/engine/v1/h2o_version_pb';
import { ListH2OVersionsRequest } from '../gen/ai/h2o/engine/v1/h2o_version_service_pb';
import { RequestConfig } from '../gen/runtime';

export type AIEMError = {
  code: number;
  message: string;
  details: any[];
};

export enum AIEMOpType {
  checkId = 'checkId',
  create = 'create',
  edit = 'edit',
  get = 'get',
  list = 'list',
  view = 'view',
  resume = 'resume',
  pause = 'pause',
  terminate = 'terminate',
  delete = 'delete',
  update = 'update',
  upgrade = 'upgrade',
  constraintSet = 'constraintSet',
  openLog = 'openLog',
  downloadLog = 'downloadLog',
  migrateCreator = 'migrateCreator',
}

export enum ConstraintSetType {
  DAI_ENGINE_CONSTRAINT_SET = 'daiEngineConstraintSet',
  H2O_ENGINE_CONSTRAINT_SET = 'h2oEngineConstraintSet',
}

export type EngineAttributes = {
  engineType?: Engine_Type;
  op?: AIEMOpType;
  id?: string;
  loginUrl?: string;
  engineNewVersion?: string;
  newCreator?: string;
};

export type AIEngine = EngineAttributes & Engine & DAIEngine & H2OEngine;

export type EngineConstraintSetSource =
  | keyof GetDAIEngineConstraintSetResponse
  | keyof GetH2OEngineConstraintSetResponse;

export type EngineConstraintSet = DAIEngineConstraintSet & H2OEngineConstraintSet;

export type EngineVersionAttributes = {
  isDefault?: boolean;
  key?: string;
  type?: Engine_Type;
};

export interface V1RawConstraintNumeric {
  min?: string;
  max?: string | null;
  default?: string;
}

export interface V1RawConstraintDuration {
  min?: string;
  max?: string | null;
  default?: string;
}

export interface V1RawDAIEngineConstraintSet {
  name?: string;
  cpu?: V1RawConstraintNumeric;
  gpu?: V1RawConstraintNumeric;
  memoryBytes?: V1RawConstraintNumeric;
  storageBytes?: V1RawConstraintNumeric;
  maxIdleDuration?: V1RawConstraintDuration;
  maxRunningDuration?: V1RawConstraintDuration;
}

export interface V1RawH2OEngineConstraintSet {
  name?: string;
  cpu?: V1RawConstraintNumeric;
  gpu?: V1RawConstraintNumeric;
  memoryBytes?: V1RawConstraintNumeric;
  nodeCount?: V1RawConstraintNumeric;
  maxIdleDuration?: V1RawConstraintDuration;
  maxRunningDuration?: V1RawConstraintDuration;
}
export interface V1GetRawDAIEngineConstraintSetResponse {
  daiEngineConstraintSet: V1RawDAIEngineConstraintSet;
}

export interface V1GetRawH2OEngineConstraintSetResponse {
  h2oEngineConstraintSet: V1RawH2OEngineConstraintSet;
}

export type EngineVersion = (DAIVersion | H2OVersion) & EngineVersionAttributes;

export type CreateEngineRequest = EngineAttributes & (CreateDAIEngineRequest | CreateH2OEngineRequest);

export type EngineState = Engine_State | DAIEngine_State | H2OEngine_State;

// all requests
export type EngineListRequest = ListEnginesRequest | ListDAIEnginesRequest | ListH2OEnginesRequest;
export type EngineCreateRequest = CreateDAIEngineRequest | CreateH2OEngineRequest;
export type EngineGetRequest = GetDAIEngineRequest | GetH2OEngineRequest;
export type EngineUpdateRequest = UpdateDAIEngineRequest;
export type EngineUpgradeRequest = DAIEngineServiceUpgradeVersionRequest;
export type EngineResumeRequest = ResumeDAIEngineRequest;
export type EnginePausedRequest = PauseDAIEngineRequest;
export type EngineDeleteRequest = DeleteDAIEngineRequest | DeleteH2OEngineRequest;
export type EngineConstraintSetRequest = GetDAIEngineConstraintSetRequest | GetH2OEngineConstraintSetRequest;
export type EngineMigrateCreatorRequest = DAIEngineServiceMigrateCreatorRequest;

// generic responses
export type EngineListResponse = {
  engines: Array<Engine | DAIEngine | H2OEngine>;
  nextPageToken?: string;
  totalSize?: number;
};
export type EngineResponse = {
  engine: AIEngine | Engine | DAIEngine | H2OEngine;
};

type ItemOp = (engine: AIEngine, abort?: boolean) => Promise<EngineResponse>;
type ListOp = (request: EngineListRequest) => Promise<EngineListResponse>;
type ConstraintOp = (request: EngineConstraintSetRequest) => Promise<EngineConstraintSet>;
type UpgradeOp = (request: AIEngine) => Promise<DAIEngineServiceUpgradeVersionResponse>;

type EndpointTypeFunction = {
  [P in keyof Engine_Type as string]: ItemOp | ListOp | ConstraintOp | UpgradeOp;
};

export type Endpoint = {
  [P in keyof AIEMOpType as string]: EndpointTypeFunction;
};

type EngineVersionListRequest = ListDAIVersionsRequest | ListH2OVersionsRequest;

type EngineTypeAttributes = {
  text: string;
  listVersions: (requestConfig: RequestConfig, request?: EngineVersionListRequest) => Promise<EngineVersion[]>;
};

export type ValidEngineType = Engine_Type.DRIVERLESS_AI | Engine_Type.H2O;

export type EngineTypeService = {
  [P in ValidEngineType]: EngineTypeAttributes;
};

export type EngineProfileOption = AdjustedDAIProfile & {
  key: DriverlessEngineProfileOptionKeyType | H2OEngineProfileOptionKeyType;
  text: string;
  dataTest: string;
  nodeCount?: number;
};

export type EngineSizeDatasetRequest = CalculateH2OEngineSizeRawDatasetRequest &
  CalculateH2OEngineSizeCompressedDatasetRequest;
