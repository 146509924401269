import { H2OEngineProfileOptionKeyType } from '../../components/AIEnginesPage/constants';
import { fetchWrapRPC } from '../../services/api';
import { defaultBasePath, defaultEnginesListRequest, defaultWorkspaceName } from '../defaults';
import { AdjustedDAIProfileService_ListAdjustedDAIProfiles } from '../gen/ai/h2o/engine/v1/adjusted_dai_profile_service_pb';
import { DAIEngineConstraintSet } from '../gen/ai/h2o/engine/v1/dai_engine_constraint_set_pb';
import {
  DAIEngineConstraintSetService_GetDAIEngineConstraintSet,
  GetDAIEngineConstraintSetRequest,
  type GetDAIEngineConstraintSetResponse,
} from '../gen/ai/h2o/engine/v1/dai_engine_constraint_set_service_pb';
import { DAIEngine, DAIEngine_State } from '../gen/ai/h2o/engine/v1/dai_engine_pb';
import {
  CreateDAIEngineRequest,
  CreateDAIEngineResponse,
  DAIEngineServiceMigrateCreatorRequest,
  DAIEngineServiceMigrateCreatorResponse,
  DAIEngineServiceUpgradeVersionRequest,
  DAIEngineServiceUpgradeVersionResponse,
  DAIEngineService_CreateDAIEngine,
  DAIEngineService_DeleteDAIEngine,
  DAIEngineService_GetDAIEngine,
  DAIEngineService_ListDAIEngines,
  DAIEngineService_MigrateCreator,
  DAIEngineService_PauseDAIEngine,
  DAIEngineService_ResumeDAIEngine,
  DAIEngineService_UpdateDAIEngine,
  DAIEngineService_UpgradeVersion,
  DeleteDAIEngineRequest,
  GetDAIEngineRequest,
  GetDAIEngineResponse,
  ListDAIEnginesRequest,
  ListDAIEnginesResponse,
  PauseDAIEngineRequest,
  PauseDAIEngineResponse,
  ResumeDAIEngineRequest,
  ResumeDAIEngineResponse,
  UpdateDAIEngineRequest,
  UpdateDAIEngineResponse,
} from '../gen/ai/h2o/engine/v1/dai_engine_service_pb';
import { DAIVersion } from '../gen/ai/h2o/engine/v1/dai_version_pb';
import { DAIVersionService_ListDAIVersions } from '../gen/ai/h2o/engine/v1/dai_version_service_pb';
import { Engine, Engine_State, Engine_Type } from '../gen/ai/h2o/engine/v1/engine_pb';
import {
  EngineService_ListEngines,
  ListEnginesRequest,
  type ListEnginesResponse,
} from '../gen/ai/h2o/engine/v1/engine_service_pb';
import { H2OEngineConstraintSet } from '../gen/ai/h2o/engine/v1/h2o_engine_constraint_set_pb';
import {
  GetH2OEngineConstraintSetRequest,
  GetH2OEngineConstraintSetResponse,
  H2OEngineConstraintSetService_GetH2OEngineConstraintSet,
} from '../gen/ai/h2o/engine/v1/h2o_engine_constraint_set_service_pb';
import { H2OEngine, H2OEngine_State } from '../gen/ai/h2o/engine/v1/h2o_engine_pb';
import {
  CalculateH2OEngineSizeCompressedDatasetRequest,
  CalculateH2OEngineSizeRawDatasetRequest,
  CreateH2OEngineRequest,
  CreateH2OEngineResponse,
  DeleteH2OEngineRequest,
  GetH2OEngineRequest,
  GetH2OEngineResponse,
  H2OEngineService_CalculateH2OEngineSizeCompressedDataset,
  H2OEngineService_CalculateH2OEngineSizeRawDataset,
  H2OEngineService_CreateH2OEngine,
  H2OEngineService_DeleteH2OEngine,
  H2OEngineService_GetH2OEngine,
  H2OEngineService_ListH2OEngines,
  H2OEngineService_TerminateH2OEngine,
  H2OEngineSize,
  ListH2OEnginesRequest,
  ListH2OEnginesResponse,
} from '../gen/ai/h2o/engine/v1/h2o_engine_service_pb';
import { H2OVersion } from '../gen/ai/h2o/engine/v1/h2o_version_pb';
import { H2OVersionService_ListH2OVersions } from '../gen/ai/h2o/engine/v1/h2o_version_service_pb';
import { RPC, RequestConfig } from '../gen/runtime';
import { generateId } from '../utils';
import { EngineConstraintSetSource, EngineTypeService } from './types';

export type AIEMError = {
  code: number;
  message: string;
  details: any[];
};

export enum AIEMOpType {
  checkId = 'checkId',
  create = 'create',
  edit = 'edit',
  get = 'get',
  list = 'list',
  view = 'view',
  resume = 'resume',
  pause = 'pause',
  terminate = 'terminate',
  delete = 'delete',
  update = 'update',
  upgrade = 'upgrade',
  constraintSet = 'constraintSet',
  openLog = 'openLog',
  downloadLog = 'downloadLog',
  migrateCreator = 'migrateCreator',
}

export enum ConstraintSetType {
  DAI_ENGINE_CONSTRAINT_SET = 'daiEngineConstraintSet',
  H2O_ENGINE_CONSTRAINT_SET = 'h2oEngineConstraintSet',
}

export type EngineAttributes = {
  engineType?: Engine_Type;
  op?: AIEMOpType;
  id?: string;
  loginUrl?: string;
  engineNewVersion?: string;
  newCreator?: string;
};

export type AIEngine = EngineAttributes & Engine & DAIEngine & H2OEngine;

export type EngineConstraintSet = DAIEngineConstraintSet & H2OEngineConstraintSet;

export type EngineVersionAttributes = {
  isDefault?: boolean;
  key?: string;
  type?: Engine_Type;
};

export interface V1RawConstraintNumeric {
  min?: string;
  max?: string | null;
  default?: string;
}

export interface V1RawConstraintDuration {
  min?: string;
  max?: string | null;
  default?: string;
}

export interface V1RawDAIEngineConstraintSet {
  name?: string;
  cpu?: V1RawConstraintNumeric;
  gpu?: V1RawConstraintNumeric;
  memoryBytes?: V1RawConstraintNumeric;
  storageBytes?: V1RawConstraintNumeric;
  maxIdleDuration?: V1RawConstraintDuration;
  maxRunningDuration?: V1RawConstraintDuration;
}

export interface V1RawH2OEngineConstraintSet {
  name?: string;
  cpu?: V1RawConstraintNumeric;
  gpu?: V1RawConstraintNumeric;
  memoryBytes?: V1RawConstraintNumeric;
  nodeCount?: V1RawConstraintNumeric;
  maxIdleDuration?: V1RawConstraintDuration;
  maxRunningDuration?: V1RawConstraintDuration;
}
export interface V1GetRawDAIEngineConstraintSetResponse {
  daiEngineConstraintSet: V1RawDAIEngineConstraintSet;
}

export interface V1GetRawH2OEngineConstraintSetResponse {
  h2oEngineConstraintSet: V1RawH2OEngineConstraintSet;
}

export type EngineVersion = (DAIVersion | H2OVersion) & EngineVersionAttributes;

export type CreateEngineRequest = EngineAttributes & (CreateDAIEngineRequest | CreateH2OEngineRequest);

export type EngineState = Engine_State | DAIEngine_State | H2OEngine_State;

// all requests
export type EngineListRequest = ListEnginesRequest | ListDAIEnginesRequest | ListH2OEnginesRequest;
export type EngineCreateRequest = CreateDAIEngineRequest | CreateH2OEngineRequest;
export type EngineGetRequest = GetDAIEngineRequest | GetH2OEngineRequest;
export type EngineUpdateRequest = UpdateDAIEngineRequest;
export type EngineUpgradeRequest = DAIEngineServiceUpgradeVersionRequest;
export type EngineResumeRequest = ResumeDAIEngineRequest;
export type EnginePausedRequest = PauseDAIEngineRequest;
export type EngineDeleteRequest = DeleteDAIEngineRequest | DeleteH2OEngineRequest;
export type EngineConstraintSetRequest = GetDAIEngineConstraintSetRequest | GetH2OEngineConstraintSetRequest;
export type EngineMigrateCreatorRequest = DAIEngineServiceMigrateCreatorRequest;

// generic responses
export type EngineListResponse = {
  engines: Array<Engine | DAIEngine | H2OEngine>;
  nextPageToken?: string;
  totalSize?: number;
};
export type EngineResponse = {
  engine: AIEngine | Engine | DAIEngine | H2OEngine;
};

type ItemOp = (engine: AIEngine, abort?: boolean) => Promise<EngineResponse>;
type ListOp = (request: EngineListRequest) => Promise<EngineListResponse>;
type ConstraintOp = (request: EngineConstraintSetRequest) => Promise<EngineConstraintSet>;
type UpgradeOp = (request: AIEngine) => Promise<DAIEngineServiceUpgradeVersionResponse>;

type EndpointTypeFunction = {
  [P in keyof Engine_Type as string]: ItemOp | ListOp | ConstraintOp | UpgradeOp;
};

type Endpoint = {
  [P in keyof AIEMOpType as string]: EndpointTypeFunction;
};

const toEngineListResponse = (
  originalResponse: EngineListResponse,
  sourceField: keyof ListEnginesResponse | keyof ListDAIEnginesResponse | keyof ListH2OEnginesResponse
): EngineListResponse => ({
  engines: originalResponse[sourceField],
  nextPageToken: originalResponse.nextPageToken,
  totalSize: originalResponse.totalSize,
});

const castEngineResponse = (
  originalResponse: EngineResponse,
  sourceField:
    | keyof CreateDAIEngineResponse
    | keyof CreateH2OEngineResponse
    | keyof GetDAIEngineResponse
    | keyof GetH2OEngineResponse
    | keyof PauseDAIEngineResponse
    | keyof ResumeDAIEngineResponse
    | keyof UpdateDAIEngineResponse
    | keyof DAIEngineServiceUpgradeVersionResponse
    | keyof DAIEngineServiceMigrateCreatorResponse
) => ({
  engine: originalResponse[sourceField],
});

const castEngineListConstraint = (
  originalResponse: GetDAIEngineConstraintSetResponse | GetH2OEngineConstraintSetResponse,
  sourceField: EngineConstraintSetSource
) => {
  return originalResponse[sourceField];
};

const abortableBag: { [P in keyof AIEMOpType as string]: boolean } = {
  [AIEMOpType.checkId]: false,
  [AIEMOpType.list]: false,
};

let controller = new AbortController(),
  signal = controller.signal;

const abortController = () => {
    controller.abort();
    controller = new AbortController();
    signal = controller.signal;
  },
  considerAborting = (op: AIEMOpType) => {
    if (abortableBag[op]) abortController();
    abortableBag[op] = true;
  },
  abortableRequest = async (request: any, op: AIEMOpType) => {
    considerAborting(op);
    try {
      return await request();
    } catch (error) {
      if (error instanceof Response) {
        if (error.status === 403) return {};
        if (error.headers.get('content-type')?.includes('application/json')) {
          const json = await error.json();
          if (json.error?.code === 20) return {};
          throw json;
        }
      }
      throw error;
    } finally {
      abortableBag[op] = false;
    }
  };

export const getEndpointCall = (requestConfig: RequestConfig, op: AIEMOpType, type = Engine_Type.UNSPECIFIED) => {
  const invalidOpError = () => new Error('Invalid Operation: Bad Engine or Parameter Name');

  const serviceCaller = <RequestMessage, ResponseMessage>(
    rpc: RPC<RequestMessage, ResponseMessage>,
    request: RequestMessage,
    abortable = false
  ) => fetchWrapRPC(rpc, requestConfig)(request, abortable ? { signal } : undefined);

  const endpoints: Endpoint = {
    [AIEMOpType.checkId]: {
      [Engine_Type.DRIVERLESS_AI]: async ({ name }: AIEngine) => {
        if (!name) throw invalidOpError();
        try {
          return castEngineResponse(
            (await abortableRequest(
              () => serviceCaller(DAIEngineService_GetDAIEngine, { name }, true),
              AIEMOpType.checkId
            )) as EngineResponse,
            'daiEngine'
          );
        } catch (error) {
          if (error instanceof Response && error.status === 403) {
            return { engine: {} as AIEngine };
          }
          throw error;
        }
      },
      [Engine_Type.H2O]: async ({ name }: AIEngine) => {
        if (!name) throw invalidOpError();
        try {
          return castEngineResponse(
            (await abortableRequest(
              () => serviceCaller(H2OEngineService_GetH2OEngine, { name }, true),
              AIEMOpType.checkId
            )) as EngineResponse,
            'h2oEngine'
          );
        } catch (error) {
          if (error instanceof Response && error.status === 403) {
            return { engine: {} as AIEngine };
          }
          throw error;
        }
      },
    },
    [AIEMOpType.create]: {
      [Engine_Type.DRIVERLESS_AI]: async (engine: AIEngine) => {
        const req: CreateEngineRequest = {
          daiEngine: {
            ...(engine as DAIEngine),
            state: DAIEngine_State.UNSPECIFIED,
          },
          parent: defaultWorkspaceName,
          daiEngineId: engine.id || generateId(engine.displayName!),
        };
        return castEngineResponse(
          (await serviceCaller(DAIEngineService_CreateDAIEngine, req)) as EngineResponse,
          'daiEngine'
        );
      },
      [Engine_Type.H2O]: async (engine: AIEngine) => {
        const req: CreateEngineRequest = {
          h2oEngine: {
            ...(engine as H2OEngine),
            state: H2OEngine_State.UNSPECIFIED,
          },
          parent: defaultWorkspaceName,
          h2oEngineId: engine.id || generateId(engine.displayName!),
        };
        return castEngineResponse(
          (await serviceCaller(H2OEngineService_CreateH2OEngine, req)) as EngineResponse,
          'h2oEngine'
        );
      },
    },
    [AIEMOpType.get]: {
      [Engine_Type.DRIVERLESS_AI]: async ({ name }: AIEngine) => {
        if (!name) throw invalidOpError();
        return castEngineResponse(
          (await serviceCaller(DAIEngineService_GetDAIEngine, { name })) as EngineResponse,
          'daiEngine'
        );
      },
      [Engine_Type.H2O]: async ({ name }: AIEngine) => {
        if (!name) throw invalidOpError();
        return castEngineResponse(
          (await serviceCaller(H2OEngineService_GetH2OEngine, { name })) as EngineResponse,
          'h2oEngine'
        );
      },
    },
    [AIEMOpType.list]: {
      [Engine_Type.UNSPECIFIED]: async (req = defaultEnginesListRequest) => {
        return toEngineListResponse(
          (await abortableRequest(
            () => serviceCaller(EngineService_ListEngines, req || defaultEnginesListRequest),
            AIEMOpType.checkId
          )) as EngineListResponse,
          'engines'
        );
      },
      [Engine_Type.DRIVERLESS_AI]: async (req: EngineListRequest) => {
        return toEngineListResponse(
          (await abortableRequest(
            serviceCaller(DAIEngineService_ListDAIEngines, req || defaultEnginesListRequest, true),
            AIEMOpType.checkId
          )) as EngineListResponse,
          'daiEngines'
        );
      },
      [Engine_Type.H2O]: async (req: EngineListRequest) => {
        return toEngineListResponse(
          (await abortableRequest(
            serviceCaller(H2OEngineService_ListH2OEngines, req || defaultEnginesListRequest, true),
            AIEMOpType.checkId
          )) as EngineListResponse,
          'h2oEngines'
        );
      },
    },
    [AIEMOpType.resume]: {
      [Engine_Type.DRIVERLESS_AI]: async ({ name }: AIEngine) => {
        if (!name) throw invalidOpError();
        return castEngineResponse(
          (await serviceCaller(DAIEngineService_ResumeDAIEngine, { name })) as EngineResponse,
          'daiEngine'
        );
      },
    },
    [AIEMOpType.pause]: {
      [Engine_Type.DRIVERLESS_AI]: async ({ name }: AIEngine) => {
        if (!name) throw invalidOpError();
        return castEngineResponse(
          (await serviceCaller(DAIEngineService_PauseDAIEngine, { name })) as EngineResponse,
          'daiEngine'
        );
      },
    },
    [AIEMOpType.terminate]: {
      [Engine_Type.H2O]: async ({ name }: AIEngine) => {
        if (!name) throw invalidOpError();
        return castEngineResponse(
          (await serviceCaller(H2OEngineService_TerminateH2OEngine, { name })) as EngineResponse,
          'h2oEngine'
        );
      },
    },
    [AIEMOpType.delete]: {
      [Engine_Type.DRIVERLESS_AI]: async ({ name }: AIEngine) => {
        if (!name) throw invalidOpError();
        return castEngineResponse(
          (await serviceCaller(DAIEngineService_DeleteDAIEngine, { name })) as EngineResponse,
          'daiEngine'
        );
      },
      [Engine_Type.H2O]: async ({ name }: AIEngine) => {
        if (!name) throw invalidOpError();
        return castEngineResponse(
          (await serviceCaller(H2OEngineService_DeleteH2OEngine, { name })) as EngineResponse,
          'h2oEngine'
        );
      },
    },
    [AIEMOpType.update]: {
      [Engine_Type.DRIVERLESS_AI]: async (engine: AIEngine) => {
        const req: EngineUpdateRequest = {
          daiEngine: engine as DAIEngine,
          updateMask: '*',
        };
        return castEngineResponse(
          (await serviceCaller(DAIEngineService_UpdateDAIEngine, req)) as EngineResponse,
          'daiEngine'
        );
      },
    },
    [AIEMOpType.upgrade]: {
      [Engine_Type.DRIVERLESS_AI]: async (engine: AIEngine) => {
        if (!engine || !engine.name) throw invalidOpError();
        if (!engine.engineNewVersion) engine.engineNewVersion = 'latest';
        const req: EngineUpgradeRequest = {
          daiEngine: engine.name,
          newVersion: engine.engineNewVersion,
        };
        return castEngineResponse(
          (await serviceCaller(DAIEngineService_UpgradeVersion, req)) as EngineResponse,
          'daiEngine'
        );
      },
    },
    [AIEMOpType.constraintSet]: {
      [Engine_Type.DRIVERLESS_AI]: async (req: EngineConstraintSetRequest) => {
        return castEngineListConstraint(
          (await serviceCaller(
            DAIEngineConstraintSetService_GetDAIEngineConstraintSet,
            req
          )) as GetDAIEngineConstraintSetResponse,
          ConstraintSetType.DAI_ENGINE_CONSTRAINT_SET
        );
      },
      [Engine_Type.H2O]: async (req: EngineConstraintSetRequest) => {
        return castEngineListConstraint(
          (await serviceCaller(
            H2OEngineConstraintSetService_GetH2OEngineConstraintSet,
            req
          )) as GetH2OEngineConstraintSetResponse,
          ConstraintSetType.H2O_ENGINE_CONSTRAINT_SET
        );
      },
    },
    [AIEMOpType.migrateCreator]: {
      [Engine_Type.DRIVERLESS_AI]: async (engine: AIEngine) => {
        const req: EngineMigrateCreatorRequest = {
          daiEngine: engine.name!,
          newCreator: engine.newCreator!,
        };
        return castEngineResponse(
          (await serviceCaller(DAIEngineService_MigrateCreator, req)) as EngineResponse,
          'daiEngine'
        );
      },
    },
  };
  return endpoints[op][type];
};

export const constraintSetTypeMap = new Map<Engine_Type, ConstraintSetType>([
  [Engine_Type.DRIVERLESS_AI, ConstraintSetType.DAI_ENGINE_CONSTRAINT_SET],
  [Engine_Type.H2O, ConstraintSetType.H2O_ENGINE_CONSTRAINT_SET],
]);

export const engineTypeService: EngineTypeService = {
  [Engine_Type.DRIVERLESS_AI]: {
    text: 'DAI',
    listVersions: async (requestConfig: RequestConfig, request = {}): Promise<EngineVersion[]> => {
      const requestService = async () => await fetchWrapRPC(DAIVersionService_ListDAIVersions, requestConfig)(request);
      return (await requestService())['daiVersions'] || [];
    },
  },
  [Engine_Type.H2O]: {
    text: 'H2O',
    listVersions: async (requestConfig: RequestConfig, request = {}): Promise<EngineVersion[]> => {
      const requestService = async () => await fetchWrapRPC(H2OVersionService_ListH2OVersions, requestConfig)(request);
      return (await requestService())['h2oVersions'] || [];
    },
  },
};

export const CalculateH2OEngineSizeCompressedDataset = async (
  basePath = defaultBasePath,
  bearerToken = '',
  request = {} as CalculateH2OEngineSizeCompressedDatasetRequest
): Promise<H2OEngineSize> => {
  const response = await fetchWrapRPC(H2OEngineService_CalculateH2OEngineSizeCompressedDataset, {
    basePath,
    bearerToken,
  })({ ...request, workspace: request.workspace || defaultWorkspaceName });
  return response.h2oEngineSize || {};
};

export const CalculateH2OEngineSizeRawDataset = async (
  basePath = defaultBasePath,
  bearerToken = '',
  request = {} as CalculateH2OEngineSizeRawDatasetRequest
): Promise<H2OEngineSize> => {
  const response = await fetchWrapRPC(H2OEngineService_CalculateH2OEngineSizeRawDataset, { basePath, bearerToken })({
    ...request,
    workspace: request.workspace || defaultWorkspaceName,
  });
  return response.h2oEngineSize || {};
};

export type CalculatedH2OEngineSizeAttributes = {
  calculate: typeof CalculateH2OEngineSizeCompressedDataset | typeof CalculateH2OEngineSizeRawDataset;
  label: string;
};

export type H2OEngineSizeService = {
  [P in
    | H2OEngineProfileOptionKeyType.raw
    | H2OEngineProfileOptionKeyType.compressed]: CalculatedH2OEngineSizeAttributes;
};

export const calculatedEngineSizes: H2OEngineSizeService = {
  [H2OEngineProfileOptionKeyType.raw]: {
    label: 'Raw',
    calculate: CalculateH2OEngineSizeRawDataset,
  },
  [H2OEngineProfileOptionKeyType.compressed]: {
    label: 'Compressed',
    calculate: CalculateH2OEngineSizeCompressedDataset,
  },
};

export const listDAIProfiles = (basePath = defaultBasePath, bearerToken = '') =>
  fetchWrapRPC(AdjustedDAIProfileService_ListAdjustedDAIProfiles, { basePath, bearerToken })({
    parent: defaultWorkspaceName,
  });
