import { MessageBarType, Stack } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { useToast } from '@h2oai/ui-kit';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { hasOp } from '../../aiem/engine/conditions';
import { FilterCondition, useEngine, useEngineQueryParams } from '../../aiem/engine/hooks';
import { AIEMOpType, AIEngine, EngineState } from '../../aiem/engine/types';
import { Engine, Engine_Type } from '../../aiem/gen/ai/h2o/engine/v1/engine_pb';
import { getIdFromName } from '../../aiem/utils';
import ListPage from '../../components/ListPages/ListPage';
import { stackStylesPage } from '../../themes/themes';
import { useCloudPlatformDiscovery, useDebouncedCallback } from '../../utils/hooks';
import FilterPanel, { FilterPanelInput } from '../FilterPanel/FilterPanel';
import { AIEMConfirmDialog } from './components/AIEMConfirmDialog/AIEMConfirmDialog';
import { AIEMMigrateCreator } from './components/AIEMMigrateCreator/AIEMMigrateCreator';
import { AIEMPanelWrapper } from './components/AIEMPanel/AIEMPanelWrapper';
import { AIEMUpgradeDialog } from './components/AIEMUpgradeDialog/AIEMUpgradeDialog';
import { EngineList } from './components/EngineList/EngineList';
import EngineVersionFilter from './components/EngineVersionFilter';
import EngineStateFilter from './components/filters/EngineStateFilter';
import { TypeVersionSelection, conditionsFromParams } from './filter.utils';

enum EngineFilterTypes {
  State = 'state',
  Version = 'version',
}

const useGetEngines = (
  fetchEngines: (filter?: FilterCondition[]) => Promise<Engine[] | undefined>,
  options: { refetchInterval?: number } = {}
) => {
  const [loading, setLoading] = useState(true);
  const [engines, setEngines] = useState<Engine[] | undefined>(undefined);
  const [triggerRefetch, setTriggerRefetch] = useState(0);
  const refetch = useCallback(() => setTriggerRefetch((i) => i + 1), []);
  const {
    params,
    setSearchTermParam: setSearchTermHook,
    setTypeVersions: setTypeVersionsHook,
    setStates: setStatesHook,
    setParams: setParamsHook,
  } = useEngineQueryParams();
  const conditions = useMemo(() => conditionsFromParams(params), [params]);
  const optionsRef = useRef(options);
  optionsRef.current = options;
  const setSearchTerm = useCallback(
    (search: string) => {
      setLoading(true);
      setSearchTermHook(search);
    },
    [setLoading, setSearchTermHook]
  );
  const setTypeVersions = useCallback(
    (value: TypeVersionSelection) => {
      setLoading(true);
      setTypeVersionsHook(value);
    },
    [setLoading, setTypeVersionsHook]
  );
  const setStates = useCallback(
    (states: string[]) => {
      setLoading(true);
      setStatesHook(states);
    },
    [setLoading, setStatesHook]
  );
  const setParams = useCallback(
    (searchTerm: string, states: string[], versions: TypeVersionSelection) => {
      setLoading(true);
      setParamsHook(searchTerm, states, versions);
    },
    [setLoading, setParamsHook]
  );

  useEffect(() => {
    const stale = { current: false };
    const timer = { current: 0 };
    fetchEngines(conditions)
      .finally(() => {
        if (stale.current) return;
        setLoading(false);
        if (optionsRef.current?.refetchInterval) {
          timer.current = window.setTimeout(refetch, optionsRef.current.refetchInterval);
        }
      })
      .then(
        (list) => {
          if (stale.current) return;
          setEngines(list);
        },
        () => {}
      );
    return () => {
      stale.current = true;
      if (timer.current) {
        window.clearTimeout(timer.current);
      }
    };
  }, [conditions, triggerRefetch]);
  return { data: engines, loading, refetch, params, setParams, setSearchTerm, setTypeVersions, setStates };
};

type AIEMPanelState = {
  op: AIEMOpType | null;
  isOpen: boolean;
  engine?: AIEngine;
};

type ConfirmDialogType = {
  op: AIEMOpType.pause | AIEMOpType.delete | AIEMOpType.resume | AIEMOpType.terminate;
};

interface AIEnginesPageProps {
  title: string;
  fetchEngines: (filter?: FilterCondition[]) => Promise<Engine[] | undefined>;
  admin?: boolean;
}

function AIEnginesPage(props: AIEnginesPageProps) {
  const { fetchEngines, admin } = props,
    { addToast } = useToast(),
    history = useHistory(),
    location = useLocation(),
    platformDiscovery = useCloudPlatformDiscovery(),
    { EngineStateMap, opOnEngine } = useEngine(),
    [filterPanelOpen, { setTrue: openFilterPanel, setFalse: dismissFilterPanel }] = useBoolean(false),
    [selectedEngineIds, setSelectedEngineIds] = useState<string[]>([]),
    engines = useGetEngines(fetchEngines, { refetchInterval: 5_000 }),
    [panel, setPanel] = useState<AIEMPanelState>({ op: AIEMOpType.create, isOpen: false }),
    [dialog, setDialog] = useState<ConfirmDialogType | null>(null),
    [upgrade, setUpgrade] = useState<AIEMOpType.upgrade | null>(null),
    [migrateCreator, setMigrateCreator] = useState<AIEMOpType.migrateCreator | null>(null),
    [actionEngine, setActionEngine] = useState<AIEngine | undefined>(),
    [bulkActionEngine, setBulkActionEngine] = useState<AIEngine[]>([]),
    readLogs = useCallback(
      ({ name }: AIEngine) => {
        history.push(`/logs/?name=${name}`);
      },
      [history]
    ),
    openLegacyEngineLogs = useCallback(
      ({ name, type }: AIEngine) => {
        const id = `${getIdFromName(name)}`;
        history.push(`${location.pathname}/log/${type}/${id}`);
      },
      [history, location]
    ),
    successToast = useCallback(
      (displayName: string, verb?: string) => {
        addToast({
          messageBarType: MessageBarType.success,
          message: `Engine "${displayName}" ${verb} successfully.`,
        });
      },
      [addToast]
    ),
    bulkSuccessToast = useCallback(
      (verb: string, itemCount: number) => {
        const mainTitle = `${String(itemCount)} ${itemCount > 1 ? 'items' : 'item'} ${verb} successfully.`;
        addToast({
          messageBarType: MessageBarType.success,
          message: mainTitle,
        });
      },
      [selectedEngineIds, addToast]
    ),
    editEngine = useCallback(
      async (engine: AIEngine) => {
        const freshEngine = await opOnEngine(engine, AIEMOpType.get),
          { engineType } = engine;
        setPanel({
          op: AIEMOpType.edit,
          isOpen: !!freshEngine,
          engine: ({ ...freshEngine, engineType } as AIEngine) || engine,
        });
      },
      [opOnEngine]
    ),
    viewEngine = useCallback(
      async (engine: AIEngine) => {
        const { engineType } = engine;
        const freshEngine = await opOnEngine(engine, AIEMOpType.get);
        setPanel({
          op: AIEMOpType.view,
          isOpen: !!freshEngine,
          engine: ({ ...freshEngine, engineType } as AIEngine) || engine,
        });
      },
      [opOnEngine]
    ),
    pauseConfirmedEngine = useCallback(
      async (engine: AIEngine) => {
        const res = await opOnEngine(engine, AIEMOpType.pause);
        setDialog(null);
        if (res) successToast(String(engine.displayName), 'is pausing');
      },
      [successToast, opOnEngine, setDialog]
    ),
    terminateConfirmedEngine = useCallback(
      async (engine: AIEngine) => {
        const res = await opOnEngine(engine, AIEMOpType.terminate);
        setDialog(null);
        if (res) successToast(String(engine.displayName), 'is terminating');
      },
      [successToast, opOnEngine, setDialog]
    ),
    deleteConfirmedEngine = useCallback(
      async (engine: AIEngine) => {
        const res = await opOnEngine(engine, AIEMOpType.delete);
        setDialog(null);
        if (res) successToast(String(engine.displayName), 'is deleting');
      },
      [successToast, opOnEngine]
    ),
    actionEngineErrorMessage = (actionName = 'Action') => {
      addToast({
        messageBarType: MessageBarType.severeWarning,
        message: `${actionName} can't be performed`,
        title: `Can't load engine data.`,
      });
    },
    onConfirmDialog = useCallback(async () => {
      if (!actionEngine?.op) {
        actionEngineErrorMessage();
        return;
      }
      const { op } = actionEngine;
      if (op === AIEMOpType.pause) await pauseConfirmedEngine(actionEngine as AIEngine);
      if (op === AIEMOpType.delete) await deleteConfirmedEngine(actionEngine as AIEngine);
      if (op === AIEMOpType.terminate) await terminateConfirmedEngine(actionEngine as AIEngine);
      engines.refetch();
    }, [actionEngine, addToast, engines.refetch, pauseConfirmedEngine, deleteConfirmedEngine]),
    onConfirmBulkDialog = useCallback(async () => {
      const { op } = dialog || {};
      if (!bulkActionEngine || !dialog || !dialog.op) {
        actionEngineErrorMessage();
        return;
      }
      if (op) {
        setDialog(null);
        const actionWaitList: Promise<any>[] = [];
        let cantResumeH2OEngineCounter = 0;
        try {
          selectedEngineIds.forEach(async (uid) => {
            const engine = engines.data?.find((e) => e.uid === uid);
            const { type, state } = engine || {};

            if (type && state && (op === AIEMOpType.delete || hasOp(op, type, state))) {
              const actionEngine = { ...engine, engineType: type } as AIEngine;
              actionWaitList.push(opOnEngine(actionEngine, op));
            } else if (op === AIEMOpType.resume && type === Engine_Type.H2O) {
              cantResumeH2OEngineCounter++;
            }
          });

          const res = await Promise.all(actionWaitList);
          if (res && dialog.op === AIEMOpType.pause) bulkSuccessToast('paused', actionWaitList.length);
          if (res && dialog.op === AIEMOpType.terminate) bulkSuccessToast('terminated', actionWaitList.length);
          if (res && dialog.op === AIEMOpType.delete) bulkSuccessToast('deleted', actionWaitList.length);
          if (res && dialog.op === AIEMOpType.resume) bulkSuccessToast('resumed', actionWaitList.length);
          if (cantResumeH2OEngineCounter) {
            addToast({
              messageBarType: MessageBarType.warning,
              message: `Can't resume ${cantResumeH2OEngineCounter} H2O engine${
                cantResumeH2OEngineCounter > 1 ? 's' : ''
              }`,
            });
          }
        } catch (error) {
          actionEngineErrorMessage();
        }

        engines.refetch();

        setBulkActionEngine([]);
      }
    }, [dialog?.op, engines.refetch, pauseConfirmedEngine, deleteConfirmedEngine]),
    onConfirmUpgradeDialog = useCallback(
      async (newVersion: string) => {
        if (!actionEngine?.op) {
          actionEngineErrorMessage('Upgrade');
          return;
        }
        actionEngine.engineNewVersion = newVersion;
        const res = await opOnEngine(actionEngine as AIEngine, AIEMOpType.upgrade);
        setUpgrade(null);
        if (res) successToast(String(actionEngine.displayName), 'was upgraded');
      },
      [actionEngine, addToast, opOnEngine]
    ),
    onConfirmMigrateCreatorDialog = useCallback(
      async (creatorId?: string) => {
        if (!actionEngine?.op) {
          actionEngineErrorMessage('Change creator');
          return;
        }
        actionEngine.newCreator = creatorId;
        const res = await opOnEngine(actionEngine, AIEMOpType.migrateCreator);
        setMigrateCreator(null);
        if (res) successToast(String(actionEngine.displayName), ' creator was changed');
      },
      [actionEngine, addToast, opOnEngine]
    ),
    deleteEngine = useCallback(
      (e: AIEngine) => {
        const op = AIEMOpType.delete;
        setBulkActionEngine([]);
        setSelectedEngineIds([]);
        setActionEngine({ ...e, op });
        setDialog({ op });
      },
      [setDialog]
    ),
    upgradeEngine = useCallback(
      (e: AIEngine) => {
        const op = AIEMOpType.upgrade;
        setActionEngine({ ...e, op });
        setUpgrade(op);
      },
      [setDialog]
    ),
    migrateEngineCreator = useCallback(
      (e: AIEngine) => {
        const op = AIEMOpType.migrateCreator;
        setActionEngine({ ...e, op });
        setMigrateCreator(op);
      },
      [setDialog]
    ),
    resumeEngine = useCallback(
      async (engine: AIEngine) => {
        setBulkActionEngine([]);
        setSelectedEngineIds([]);
        const res = await opOnEngine(engine, AIEMOpType.resume);
        if (res) successToast(String(engine.displayName), 'is resuming');
        engines.refetch();
      },
      [engines.refetch, successToast, opOnEngine]
    ),
    pauseEngine = useCallback(
      (e: AIEngine) => {
        setBulkActionEngine([]);
        setSelectedEngineIds([]);
        const op = AIEMOpType.pause;
        setActionEngine({ ...e, op });
        setDialog({ op });
      },
      [setDialog, setActionEngine]
    ),
    terminateEngine = useCallback(
      (e: AIEngine) => {
        setBulkActionEngine([]);
        setSelectedEngineIds([]);
        const op = AIEMOpType.terminate;
        setActionEngine({ ...e, op });
        setDialog({ op });
      },
      [setDialog, setActionEngine]
    ),
    bulkAction = useCallback(
      async (op: AIEMOpType.delete | AIEMOpType.pause | AIEMOpType.resume | AIEMOpType.terminate) => {
        const selectedEngines =
          (engines.data?.filter(({ uid }) => uid && selectedEngineIds.includes(uid)) as AIEngine[]) || [];
        setBulkActionEngine(selectedEngines);
        setDialog({ op });
      },
      [selectedEngineIds]
    ),
    onDismissDialog = useCallback(() => {
      setDialog(null);
    }, [setDialog]),
    onDismissUpgradeDialog = useCallback(() => {
      setUpgrade(null);
    }, [setUpgrade]),
    onDismissMigrateCreatorDialog = useCallback(() => {
      setMigrateCreator(null);
    }, [setMigrateCreator]),
    onCreateClick = () => {
      setPanel({ op: AIEMOpType.create, isOpen: true });
    },
    dismissPanel = useCallback(() => {
      setPanel({ op: null, isOpen: false, engine: undefined });
    }, []),
    onSavePanel = useCallback(async () => {
      dismissPanel();
      engines.refetch();
    }, [actionEngine, engines.refetch, panel.op, successToast, opOnEngine]);

  const debouncedSetSearchParam = useDebouncedCallback(engines.setSearchTerm, 300);
  const [searchKey, setSearchKey] = useState(engines.params.searchTerm);
  const onChangeSearchInput = useCallback(
      (_, value) => {
        const newValue = value || '';
        setSearchKey(newValue);
        debouncedSetSearchParam(newValue);
      },
      [debouncedSetSearchParam, setSearchKey]
    ),
    onResetFilters = useCallback(() => {
      engines.setParams(searchKey, [], {});
    }, [engines, searchKey]);

  const activeFilters = useMemo(() => {
    function deriveVersionFilters(engineType: Engine_Type, label: string) {
      const versionMap = engines.params?.versions?.[engineType];
      if (!versionMap) return [];
      const filteredVersions = Object.keys(versionMap).filter((version) => versionMap[version]);
      return filteredVersions.map((version) => ({
        key: `${label}-${version}`,
        label: `${label} ${version}`,
        data: {
          type: EngineFilterTypes.Version,
          engineType,
          version,
        },
      }));
    }

    const stateFilters = engines.params?.states?.map((state) => {
      const title = EngineStateMap.get(state as EngineState)?.title;
      return {
        key: `state-${state}`,
        label: title || 'Unknown state',
        data: {
          type: EngineFilterTypes.State,
          state,
        },
      };
    });

    return [
      ...stateFilters,
      ...deriveVersionFilters(Engine_Type.DRIVERLESS_AI, 'DAI'),
      ...deriveVersionFilters(Engine_Type.H2O, 'H2O'),
    ];
  }, [engines.params]);

  return (
    <>
      <FilterPanel isOpen={filterPanelOpen} onDismiss={dismissFilterPanel}>
        <FilterPanelInput>
          <EngineVersionFilter />
        </FilterPanelInput>
        <FilterPanelInput>
          <EngineStateFilter onChangeStates={engines.setStates} />
        </FilterPanelInput>
      </FilterPanel>
      {panel.isOpen && (
        <AIEMPanelWrapper
          onDismiss={dismissPanel}
          op={panel.op || AIEMOpType.create}
          engine={panel.engine!}
          onSave={onSavePanel}
        />
      )}
      <ListPage
        actions={[
          {
            'data-test': 'bulk-resume-engine-button',
            key: 'bulk-resume-engine',
            text: 'Resume',
            onClick() {
              bulkAction(AIEMOpType.resume);
            },
          },
          {
            'data-test': 'bulk-pause-engine-button',
            key: 'bulk-pause-engine',
            text: 'Pause',
            onClick() {
              bulkAction(AIEMOpType.pause);
            },
          },
          {
            'data-test': 'bulk-pause-engine-button',
            key: 'bulk-pause-engine',
            text: 'Terminate',
            onClick() {
              bulkAction(AIEMOpType.terminate);
            },
          },
          {
            'data-test': 'bulk-delete-engine-button',
            key: 'bulk-delete-engine',
            text: 'Delete',
            dangerous: true,
            onClick() {
              bulkAction(AIEMOpType.delete);
            },
          },
        ]}
        activeFilters={activeFilters}
        copy={{
          title: props.title,
          subtitle: `You have ${engines.data?.length || 0} AI engines`,
          loadingMessage: 'Loading AI Engines...',
          noDataMessage: 'No engines found',
          noFilterResultsMessage: 'No AI engines match these filters',
          searchPlaceholder: 'Search by Engine Display Name or ID',
        }}
        disableActions={selectedEngineIds.length === 0}
        loading={engines.loading || !engines.data}
        primaryButtonProps={{
          text: 'Create AI Engine',
          onClick: onCreateClick,
        }}
        showFilterButton
        showNoDataPage={Boolean(engines.data?.length === 0 && !searchKey)}
        showNoResults={Boolean(engines.data?.length === 0 && searchKey)}
        searchText={searchKey}
        onChangeSearchText={onChangeSearchInput}
        onOpenFilterPanel={openFilterPanel}
        onRemoveFilter={({ data }) => {
          if (data?.type === EngineFilterTypes.Version) {
            const engineType = data?.engineType;
            const versionToRemove = data?.version;
            if (engineType && versionToRemove) {
              const nextVersionParams = { ...(engines.params?.versions?.[engineType] || {}) };
              delete nextVersionParams[versionToRemove];
              engines.setTypeVersions({
                ...engines.params?.versions,
                [engineType]: nextVersionParams,
              });
            }
          }
          if (data?.type === EngineFilterTypes.State) {
            const stateToRemove = data?.state;
            if (stateToRemove) {
              engines.setStates(engines.params?.states?.filter((s) => s !== stateToRemove));
            }
          }
        }}
        onResetFilters={onResetFilters}
      >
        {platformDiscovery?.aiEngineManagerApiUrl ? (
          <>
            <EngineList
              engines={engines.data}
              viewEngine={viewEngine}
              editEngine={editEngine}
              resumeEngine={resumeEngine}
              pauseEngine={pauseEngine}
              terminateEngine={terminateEngine}
              deleteEngine={deleteEngine}
              upgradeEngine={upgradeEngine}
              migrateCreator={migrateEngineCreator}
              admin={admin}
              readLogs={readLogs}
              openLegacyEngineLogs={openLegacyEngineLogs}
              selectedEngineIds={selectedEngineIds}
              setSelectedEngineIds={setSelectedEngineIds}
            />
            <AIEMConfirmDialog
              hidden={!dialog}
              engines={bulkActionEngine.length > 0 ? bulkActionEngine : actionEngine ? [actionEngine] : []}
              op={dialog?.op || AIEMOpType.pause}
              onDismiss={onDismissDialog}
              onConfirm={bulkActionEngine.length > 0 ? onConfirmBulkDialog : onConfirmDialog}
            />
            <AIEMUpgradeDialog
              hidden={!upgrade}
              engine={actionEngine}
              onDismiss={onDismissUpgradeDialog}
              onConfirm={onConfirmUpgradeDialog}
            />
            <AIEMMigrateCreator
              hidden={!migrateCreator}
              engine={actionEngine}
              onConfirm={onConfirmMigrateCreatorDialog}
              onDismiss={onDismissMigrateCreatorDialog}
            />
          </>
        ) : (
          <Stack styles={stackStylesPage}>
            <h2 style={{ textAlign: 'center' }}>Sorry, AI Engine Manager has not been enabled for this environment.</h2>
          </Stack>
        )}
      </ListPage>
    </>
  );
}

export default AIEnginesPage;
