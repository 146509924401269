// @generated by protoc-gen-grpc-gateway-es v0.1.6 with parameter "target=ts,import_extension=none"
// @generated from file ai/h2o/engine/v1/dai_engine.proto (package ai.h2o.engine.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BigIntString } from "../../../../runtime";

/**
 * Message representing Driverless AI Engine.
 * (-- api-linter: core::0164::declarative-friendly-required=disabled
 *     aip.dev/not-precedent: Not adding Undelete method because it requires
 *     changes on generic Engine and H2OEngine as well. Undelete method should
 *     be added later. --)
 *
 * @generated from message ai.h2o.engine.v1.DAIEngine
 */
export type DAIEngine = {
/**
 * DAIEngine resource name.
 *
 * @generated from field: string name = 1;
 */
name?: string;
/**
 * Globally unique identifier of the resource.
 *
 * @generated from field: string uid = 2;
 */
uid?: string;
/**
 * Name of an entity that created the DAIEngine.
 *
 * @generated from field: string creator = 3;
 */
creator?: string;
/**
 * Human-readable name of creator.
 *
 * @generated from field: string creator_display_name = 4;
 */
creatorDisplayName?: string;
/**
 * The current state of the DAIEngine.
 *
 * @generated from field: ai.h2o.engine.v1.DAIEngine.State state = 10;
 */
state?: DAIEngine_State;
/**
 * Indicates whether changes to the resource are in progress.
 * If true, current state of the DAIEngine does not match the intended state.
 *
 * @generated from field: bool reconciling = 11;
 */
reconciling?: boolean;
/**
 * Indicates whether the DAIEngine disk is currently resizing.
 * It make take hours to complete the resizing process, especially
 * if the disk has been resized recently.
 *
 * @generated from field: bool disk_resizing = 12;
 */
diskResizing?: boolean;
/**
 * Total disk size in bytes as reported by DriverlessAI.
 * Available only in 1.10.6 and higher.
 *
 * @generated from field: optional int64 total_disk_size_bytes = 13;
 */
totalDiskSizeBytes?: BigIntString;
/**
 * Free disk size in bytes as reported by DriverlessAI.
 * Available only in 1.10.6 and higher.
 *
 * @generated from field: optional int64 free_disk_size_bytes = 14;
 */
freeDiskSizeBytes?: BigIntString;
/**
 * Time when the DAIEngine creation was requested.
 *
 * @generated from field: google.protobuf.Timestamp create_time = 21;
 */
createTime?: string;
/**
 * Time when the DAIEngine was deleted.
 *
 * @generated from field: optional google.protobuf.Timestamp delete_time = 22;
 */
deleteTime?: string;
/**
 * Time when the DAIEngine was last updated.
 *
 * @generated from field: optional google.protobuf.Timestamp update_time = 23;
 */
updateTime?: string;
/**
 * Time when the DAIEngine was last resumed (or started for the first time).
 *
 * @generated from field: optional google.protobuf.Timestamp resume_time = 24;
 */
resumeTime?: string;
/**
 * The URL address to access the engine API.
 *
 * @generated from field: string api_url = 25;
 */
apiUrl?: string;
/**
 * The URL address to initiate login flow.
 *
 * @generated from field: string login_url = 26;
 */
loginUrl?: string;
/**
 * Additional arbitrary metadata associated with the DAIEngine.
 *
 * Annotations are key/value pairs.
 * The key must:
 * - be 63 characters or less
 * - begin and end with an alphanumeric character ([a-z0-9A-Z])
 * - with dashes (-), underscores (_), dots (.), and alphanumerics between
 * - regex used for validation is: ^[A-Za-z0-9]([-A-Za-z0-9_.]{0,61}[A-Za-z0-9])?$
 *
 * @generated from field: map<string, string> annotations = 100;
 */
annotations?: { [key: string]: string };
/**
 * Human-readable name of the DAIEngine. Must contain at most 63 characters. Does not have to be unique.
 *
 * @generated from field: string display_name = 101;
 */
displayName?: string;
/**
 * Driverless AI version identifier. Version may be specified during engine creation but cannot be changed later.
 * For example: "1.10.1" or "latest".
 *
 * @generated from field: optional string version = 102;
 */
version?: string;
/**
 * The amount of CPU units requested by this DAIEngine.
 *
 * @generated from field: optional int32 cpu = 103;
 */
cpu?: number;
/**
 * The amount of GPU units requested by this DAIEngine.
 *
 * @generated from field: optional int32 gpu = 104;
 */
gpu?: number;
/**
 * The amount of memory in bytes requested by this DAIEngine.
 *
 * @generated from field: optional int64 memory_bytes = 105;
 */
memoryBytes?: BigIntString;
/**
 * The amount of storage requested by this DAIEngine.
 *
 * @generated from field: optional int64 storage_bytes = 106;
 */
storageBytes?: BigIntString;
/**
 * Additional Driverless AI configuration.
 *
 * Temporary: Some config keys are reserved and their value cannot be changed.
 * Attempting to set or override a reserved key will have no effect, no error
 * will be raised.
 * Entries with reserved keys will not be available in this field (they are
 * hidden).
 *
 * @generated from field: map<string, string> config = 107;
 */
config?: { [key: string]: string };
/**
 * Maximum time the DAIEngine can be idle. When exceeded, the DAIEngine will pause.
 * The idle time is reset when any of the following occurs:
 * - incoming RPC request from client
 * - user login or logout
 * - system event like sync message from running or finished experiment
 * - initialization of dataset upload
 * - custom recipe upload
 *
 * @generated from field: optional google.protobuf.Duration max_idle_duration = 108;
 */
maxIdleDuration?: string | null;
/**
 * Maximum time the DAIEngine can be running. When exceeded, the DAIEngine will pause.
 *
 * @generated from field: optional google.protobuf.Duration max_running_duration = 109;
 */
maxRunningDuration?: string | null;
/**
 * Indicates, whether the used version can be upgraded to a later one.
 *
 * @generated from field: bool upgrade_available = 110;
 */
upgradeAvailable?: boolean;
/**
 * Current time the DAIEngine is idle.
 *
 * @generated from field: optional google.protobuf.Duration current_idle_duration = 111;
 */
currentIdleDuration?: string;
/**
 * Current time the DAIEngine is running.
 *
 * @generated from field: optional google.protobuf.Duration current_running_duration = 112;
 */
currentRunningDuration?: string;
}
;
/**
 * All possible DAIEngine states.
 *
 * @generated from enum ai.h2o.engine.v1.DAIEngine.State
 */
export enum DAIEngine_State {
/**
 * DAIEngine state is unspecified or unknown.
 *
 * @generated from enum value: STATE_UNSPECIFIED = 0;
 */
UNSPECIFIED = "STATE_UNSPECIFIED",

/**
 * DAIEngine is starting.
 *
 * @generated from enum value: STATE_STARTING = 1;
 */
STARTING = "STATE_STARTING",

/**
 * DAIEngine is running and can be used.
 *
 * @generated from enum value: STATE_RUNNING = 2;
 */
RUNNING = "STATE_RUNNING",

/**
 * DAIEngine is pausing.
 *
 * @generated from enum value: STATE_PAUSING = 3;
 */
PAUSING = "STATE_PAUSING",

/**
 * DAIEngine is paused and can be resumed again.
 *
 * @generated from enum value: STATE_PAUSED = 4;
 */
PAUSED = "STATE_PAUSED",

/**
 * DAIEngine has failed and can be resumed again.
 *
 * @generated from enum value: STATE_FAILED = 5;
 */
FAILED = "STATE_FAILED",

/**
 * DAIEngine is being deleted.
 *
 * @generated from enum value: STATE_DELETING = 6;
 */
DELETING = "STATE_DELETING",

/**
 * DAIEngine is establishing a connection with the AI Engine Manager.
 *
 * @generated from enum value: STATE_CONNECTING = 7;
 */
CONNECTING = "STATE_CONNECTING",
}
