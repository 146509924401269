import '@h2oai/ui-kit/lib/assets/css/include-inter.css';
import '@h2oai/ui-kit/lib/assets/css/variables.css';
import { changeTheme } from '@h2oai/ui-kit';
import { User } from 'oidc-client-ts';
import ReactDOM from 'react-dom';
import { AuthProvider, AuthProviderProps } from 'react-oidc-context';

import App from './App/App';
import { getClient, getEnvironment, listServices } from './discovery-service/api';
import { clientNames, serviceNames } from './discovery-service/constants';
import { ListServicesUtilResponse } from './discovery-service/models';
import './index.css';
import { OIDC_KEY_KEY, VALID_HOSTS } from './services/api';
import { OidcSession } from './test/mocks/OidcSession';
import { CloudPlatformDiscoveryProvider } from './utils/contexts';
import { getUriHost, getUrlFromUri } from './utils/utils';

// TODO: Add React.StrictMode when fluentui team will fix all the errors or submit PRs and do it for them.
// https://github.com/microsoft/fluentui/issues/4613

function deriveServiceUrl(services: ListServicesUtilResponse, serviceName: string): string | undefined {
  if (!services) return undefined;
  const service = services.find((service) => service.name === serviceName);
  return getUrlFromUri(service?.uri);
}

const handleError = (error?: any) => {
  ReactDOM.render(
    <div style={{ display: 'flex', alignItems: 'center', height: '100vh' }}>
      <div style={{ margin: '0 25%' }}>
        <h2>An error occurred while attempting to retrieve identity provider information.</h2>
        {error ? <p>{error}</p> : <></>}
      </div>
    </div>,
    document.getElementById('root')
  );
};

async function loadApp() {
  try {
    const [environmentResponse, clientResponse, listServicesResponse] = await Promise.all([
      getEnvironment(),
      getClient(clientNames.platform),
      listServices({ pageSize: 1000 }),
    ]);
    const aiEngineManagerApiUrl = deriveServiceUrl(listServicesResponse, serviceNames.aiem);
    const loggingServicesApiUrl = deriveServiceUrl(listServicesResponse, serviceNames.logging);
    const telemetryApiUrl = deriveServiceUrl(listServicesResponse, serviceNames.telemetry);
    const providerUrl = environmentResponse?.issuerUrl;
    const platformClientId = clientResponse?.oauth2ClientId;
    const h2oCloudEnvironment = environmentResponse?.h2oCloudEnvironment;
    if (!providerUrl || !platformClientId) {
      let error = '';
      if (!platformClientId) error += 'No platform client ID available from the identity provider. ';
      if (!providerUrl) error += 'No platform identity provider location available. ';
      handleError(error);
      return;
    }
    window.localStorage.setItem(OIDC_KEY_KEY, `oidc.user:${providerUrl}:${platformClientId}`);

    const hosts: string[] = [];

    if (aiEngineManagerApiUrl) hosts.push(getUriHost(aiEngineManagerApiUrl));
    if (loggingServicesApiUrl) hosts.push(getUriHost(loggingServicesApiUrl));
    if (telemetryApiUrl) hosts.push(getUriHost(telemetryApiUrl));
    if (hosts.length) window.localStorage.setItem(VALID_HOSTS, `${hosts.join(',')}`);
    if (
      process.env.NODE_ENV === 'development' &&
      process.env.REACT_APP_USE_MOCK_SERVER &&
      !process.env.REACT_APP_USE_IDP
    ) {
      // set fake session for using mock server for local development and testing
      window.sessionStorage.setItem(`oidc.user:${providerUrl}:${platformClientId}`, JSON.stringify(OidcSession));
    }
    const onSigninCallback = (_user: User | void): void => {
      window.history.replaceState({}, document.title, window.location.pathname);
    };

    const oidcConfig: AuthProviderProps = {
      client_id: platformClientId,
      authority: providerUrl,
      onSigninCallback,
      post_logout_redirect_uri: `${window.location.origin}/logout`,
      redirect_uri: window.location.origin + window.location.pathname,
      revokeTokensOnSignout: true,
    };

    const primary = document.querySelector('body')?.getAttribute('data-primary-color');
    if (primary) {
      const currPrimary = document.documentElement.style.getPropertyValue('--h2o-primary500');
      if (primary !== currPrimary) changeTheme({ primary });
    }

    ReactDOM.render(
      <CloudPlatformDiscoveryProvider
        discovery={{
          aiEngineManagerApiUrl,
          loggingServicesApiUrl,
          telemetryApiUrl,
          providerUrl,
          platformClientId,
          h2oCloudEnvironment,
        }}
      >
        <AuthProvider {...oidcConfig}>
          <App />
        </AuthProvider>
      </CloudPlatformDiscoveryProvider>,
      document.getElementById('root')
    );
  } catch (error) {
    handleError(error);
  }
}

loadApp();
