import { AIEngine, EngineProfileOption } from '../../aiem/engine/types';
import {
  CalculateH2OEngineSizeCompressedDatasetRequest,
  CalculateH2OEngineSizeRawDatasetRequest,
} from '../../aiem/gen/ai/h2o/engine/v1/h2o_engine_service_pb';
import { toBigIntString } from '../../aiem/gen/runtime';
import { gibibytesToBigIntStringBytes } from '../../aiem/utils';

export enum DriverlessEngineProfileOptionKeyType {
  small = 'small',
  medium = 'medium',
  large = 'large',
  xl = 'xl',
  xxl = 'xxl',
  custom = 'custom',
}

export enum H2OEngineProfileOptionKeyType {
  default = 'default',
  custom = 'custom',
  raw = 'raw',
  compressed = 'compressed',
}

export const H2OCalculatingEngineSizeOptionKey: Array<
  H2OEngineProfileOptionKeyType.raw | H2OEngineProfileOptionKeyType.compressed
> = [H2OEngineProfileOptionKeyType.raw, H2OEngineProfileOptionKeyType.compressed];

export const customDAIEngineSizeOption: EngineProfileOption = {
  key: DriverlessEngineProfileOptionKeyType.custom,
  text: 'Custom',
  dataTest: 'dai-custom',
};

export const DriverlessEngineSizeOptions: EngineProfileOption[] = [
  {
    key: DriverlessEngineProfileOptionKeyType.small,
    text: 'View data, interpretability, results',
    dataTest: 'dai-m5.xlarge',
    cpu: 6,
    gpu: 0,
    memoryBytes: gibibytesToBigIntStringBytes(22),
    storageBytes: gibibytesToBigIntStringBytes(64),
  },
  {
    key: DriverlessEngineProfileOptionKeyType.medium,
    text: 'AutoML',
    dataTest: 'dai-m5.4xlarge',
    cpu: 14,
    gpu: 0,
    memoryBytes: gibibytesToBigIntStringBytes(54),
    storageBytes: gibibytesToBigIntStringBytes(64),
  },
  {
    key: DriverlessEngineProfileOptionKeyType.large,
    text: 'AutoML fast or on 10+GB of data',
    dataTest: 'dai-m5.12xlarge',
    cpu: 46,
    gpu: 0,
    memoryBytes: gibibytesToBigIntStringBytes(182),
    storageBytes: gibibytesToBigIntStringBytes(64),
  },
  {
    key: DriverlessEngineProfileOptionKeyType.xl,
    text: 'NLP or Image AutoML experiments',
    dataTest: 'dai-g4dn.4xlarge',
    cpu: 14,
    gpu: 1,
    memoryBytes: gibibytesToBigIntStringBytes(54),
    storageBytes: gibibytesToBigIntStringBytes(64),
  },
  {
    key: DriverlessEngineProfileOptionKeyType.xxl,
    text: 'NLP or Image AutoML experiments on 10+GB of data',
    dataTest: 'dai-g4dn.12xlarge',
    cpu: 46,
    gpu: 4,
    memoryBytes: gibibytesToBigIntStringBytes(182),
    storageBytes: gibibytesToBigIntStringBytes(64),
  },
  customDAIEngineSizeOption,
];

export const H2OEngineSizeOptions: EngineProfileOption[] = [
  {
    key: H2OEngineProfileOptionKeyType.custom,
    text: 'Custom',
    dataTest: 'h2o-custom',
  },
  {
    key: H2OEngineProfileOptionKeyType.raw,
    text: 'Custom for Raw Data',
    dataTest: 'h2o-custom-raw',
  },
  {
    key: H2OEngineProfileOptionKeyType.compressed,
    text: 'Custom for Compressed Data',
    dataTest: 'h2o-custom-compressed',
  },
];
export const DefaultDriverlessEngineSize = DriverlessEngineProfileOptionKeyType.custom;
export const DefaultH2OEngineSize = H2OEngineProfileOptionKeyType.custom;

const driverlessDefaultSize = DriverlessEngineSizeOptions.find((o) => o.key === DefaultDriverlessEngineSize);
const h2oDefaultSize = H2OEngineSizeOptions.find((o) => o.key === DefaultH2OEngineSize);

const emptyEngineSharedFields = {
  id: undefined,
  displayName: undefined,
  version: '',
  maxIdleDuration: undefined,
  maxRunningDuration: undefined,
  annotations: undefined,
};

export const EmptyDAIEngine: AIEngine = {
  ...emptyEngineSharedFields,
  cpu: driverlessDefaultSize?.cpu,
  gpu: driverlessDefaultSize?.gpu,
  memoryBytes: driverlessDefaultSize?.memoryBytes,
  storageBytes: driverlessDefaultSize?.storageBytes,
};

export const EmptyH2OEngine: AIEngine = {
  ...emptyEngineSharedFields,
  cpu: h2oDefaultSize?.cpu,
  gpu: h2oDefaultSize?.gpu,
  memoryBytes: h2oDefaultSize?.memoryBytes,
  storageBytes: h2oDefaultSize?.storageBytes,
  nodeCount: 1,
};

export const defaultH2OCompressedEngineSizeRequest: Partial<CalculateH2OEngineSizeCompressedDatasetRequest> = {
  rowsCount: toBigIntString('10000'),
  columnsCount: toBigIntString('10'),
};

export const defaultH2ORawEngineSizeRequest: Partial<CalculateH2OEngineSizeRawDatasetRequest> = {
  datasetSizeBytes: gibibytesToBigIntStringBytes(10),
};
